'use strict';

/**
 *  Import modules
 */
//import axios from 'axios';
// import '../../../node_modules/slick-carousel/slick/slick.js';

(function ($) {
    $(document).ready(function () {

        // add class "touch" for touch devices
        if ("ontouchstart" in window) {
            document.documentElement.className =
                document.documentElement.className + " touch";
        }


        //menu open/close and change bg-color menu/first screen
        $('.menu-btn').click(function () {
            var colors = ["#ed8b00", "#f4364c", "#FEDD00", "#2F6F7A", "#B5BD00", "#101820, #63666A"];
            var rand = Math.floor(Math.random() * colors.length);
            $('section.menu').addClass('open');
            $('.change, .change-text').css("background-color", colors[rand]);
        });
        $('section.menu .close').click(function () {
            $('section.menu').removeClass('open');
        });


        var $prevArrow, $nextArrow;
        $prevArrow = "<button class=\"slick-prev\"><svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 249.9 500\">\n" +
            "    <g id=\"_764-arrow-down-selected_2x.png\" data-name=\"764-arrow-down-selected@2x.png\">\n" +
            "        <path class=\"cls-1\"\n" +
            "              d=\"M8.9,0A8.92,8.92,0,0,0,0,8.9a9.13,9.13,0,0,0,2.5,6.2h0L228.7,250,2.5,484.9h0A9.13,9.13,0,0,0,0,491.1a8.84,8.84,0,0,0,15.3,6.1h0L247.4,256.1h0a8.94,8.94,0,0,0,0-12.4h0L15.3,2.6h0A9.33,9.33,0,0,0,8.9,0Z\"/>\n" +
            "    </g>\n" +
            "</svg></button>";
        $nextArrow = "<button class=\"slick-next\"><svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 249.9 500\">\n" +
            "    <g id=\"_764-arrow-down-selected_2x.png\" data-name=\"764-arrow-down-selected@2x.png\">\n" +
            "        <path class=\"cls-1\"\n" +
            "              d=\"M8.9,0A8.92,8.92,0,0,0,0,8.9a9.13,9.13,0,0,0,2.5,6.2h0L228.7,250,2.5,484.9h0A9.13,9.13,0,0,0,0,491.1a8.84,8.84,0,0,0,15.3,6.1h0L247.4,256.1h0a8.94,8.94,0,0,0,0-12.4h0L15.3,2.6h0A9.33,9.33,0,0,0,8.9,0Z\"/>\n" +
            "    </g>\n" +
            "</svg></button>";
        $('.header-slider').slick({
            dots: false,
            fade: true,
            arrows: false,
            infinite: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        });
        $('.armature .slick-slider').slick({
            dots: true,
            infinite: true,
            variablewidth: true,
            speed: 500,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1
        });
        //project mobile slick
        $('.project-gallery.mobile .slick-slider').each(function () {
            $(this).slick({
                dots: true,
                infinite: true,
                variablewidth: true,
                adaptiveHeight: true,
                speed: 500,
                autoplay: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                // lazyLoad: 'ondemand'
            });
        });
        $( window ).on ("load", function() {
            $('.project-gallery.mobile').each(function(){
              $(this).on('init afterChange', function(){
                  var heightActiveSlider = $('.project-gallery.mobile .slick-active').height();
                  $(".project-gallery.mobile .slick-list").height(heightActiveSlider);
              });
            })
        });

        //homepage mobile slick
        $('.journal-wrap-mob .slick-slider').slick({
            dots: true,
            infinite: true,
            variablewidth: true,
            speed: 500,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1
        });
        //studio slick
        $('.studio-slick').slick({
            centerMode: true,
            centerPadding: '60px',
            slidesToShow: 3,
            prevArrow: $prevArrow,
            nextArrow: $nextArrow,
            // variablewidth: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: true,
                        prevArrow: $prevArrow,
                        nextArrow: $nextArrow,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 660,
                    settings: {
                        arrows: true,
                        prevArrow: $prevArrow,
                        nextArrow: $nextArrow,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1
                    }
                }
            ]
        });

        $(".studio-slick.slick-initialized").show();

        //change color menu-btn
        if (($('.wsm').length) && ($('.journal-wrap').length) && ($('.contact').length)) {
            // var windowHeight = $(window).height();
            // var menu_start = 0;
            // var menuchange = $('.wsm');
            // var menuchange2 = $('.journal-wrap');
            // var offsetmenu = menuchange.offset();
            // var offsetmenu2 = menuchange2.offset();
            // $(document).scroll(function () {
            // menu_start = $(this).scrollTop();
            // if ((menu_start > offsetmenu.top) && (menu_start < offsetmenu2.top)) {
            // $('.menu-btn span').css('background-color', '#000000');
            // } else if (menu_start > offsetmenu2.top) {
            // $('.menu-btn span').css('background-color', '#fff');
            // } else{
            // $('.menu-btn span').css('background-color', '#fff');
            // }
            // });


            //change color logo
            var logo_start = 0;
            var logochange = $('.contact');
            var offsetlogo = logochange.offset();
        }


    });


    //header on scroll begin
    //Firefox
    $('body').not('.home').bind('DOMMouseScroll', function (e) {
        if (e.originalEvent.detail > 0) {
            //scroll down
            $('#header').removeClass('hasScrolled');
        } else {
            var winPos = $(window).scrollTop();
            if (winPos >= 150) {
                $('#header').addClass('hasScrolled');
            } else {
                $('#header').removeClass('hasScrolled');
            }
        }
    });

    //IE, Opera, Safari
    $('body').not('.home').bind('mousewheel', function (e) {
        if (e.originalEvent.wheelDelta < 0) {
            //scroll down
            $('#header').removeClass('hasScrolled');
        } else {
            var winPos = $(window).scrollTop();
            if (winPos >= 150) {
                $('#header').addClass('hasScrolled');
            } else {
                $('#header').removeClass('hasScrolled');
            }
        }
    });
    //header on scroll end

    $(document).scroll(function () {
        if ($(window).width() > 1100) {
            if ($('.contact').length) {
                var logo_start = 0;
                var logochange = $('.contact');
                var offsetlogo = logochange.offset();
                logo_start = $(this).scrollTop();
                if (logo_start > offsetlogo.top) {
                    $('.logo').addClass('black');
                } else {
                    $('.logo').removeClass('black');
                }
            }
        }
    });

    $(document).ready(function () {


        //filter for projects
        jQuery(function ($) {
            $('#filter').change(function () {
                var filter = $('#filter');
                var $dataTerm;
                $dataTerm = $(".post-type-archive-projects #main .archive-header .filter li.active").data("term");
                // console.log($dataTerm);


                $.ajax({
                    url: filter.attr('action'),
                    data: filter.serialize(),

                    type: filter.attr('method'), // POST
                    beforeSend: function (xhr) {
                    },
                    success: function (data) {
                        loadmore_params.current_page = 1;
                        // set the new query parameters
                        loadmore_params.posts = data.posts;
                        // set the new max page parameter
                        loadmore_params.max_page = data.max_page;
                        $('#response').html(data); // insert data
                    }
                });
                return false;
            });

        });


        // play/pause button
        if ($(".video-overlay").length) {
            $('.video-overlay').parent().click(function () {
                if ($(this).children("#project-video-item").get(0).paused) {
                    $(this).children("#project-video-item").get(0).play();
                    $(this).children(".video-overlay").children(".play").fadeOut();
                } else {
                    $(this).children("#project-video-item").get(0).pause();
                    $(this).children(".video-overlay").children(".play").fadeIn();
                }
            });
        }

        // studio members popup
        $('.view--popup').on("click", function (e) {

            /* remove scroll class for header */
            $('body').bind('DOMMouseScroll', function (e) {
                if (e.originalEvent.detail > 0) {
                    //scroll down
                    $('#header').removeClass('hasScrolled');
                } else {
                    var winPos = $(window).scrollTop();
                    if (winPos >= 150) {
                        $('#header').removeClass('hasScrolled');
                    } else {
                        $('#header').removeClass('hasScrolled');
                    }
                }
            });
            $('body').bind('mousewheel', function (e) {
                if (e.originalEvent.wheelDelta < 0) {
                    //scroll down
                    $('#header').removeClass('hasScrolled');
                } else {
                    var winPos = $(window).scrollTop();
                    if (winPos >= 150) {
                        $('#header').removeClass('hasScrolled');
                    } else {
                        $('#header').removeClass('hasScrolled');
                    }
                }
            });

            $(".studio-popup").show();
            $("body").addClass("popup-open");
            $("html").addClass("popup-open");

            var $popup_id = $(this).attr('data-popup');
            $(".resume-popup").each(function () {
                $(this).hide();
                if ($(this).attr('data-popup') == $popup_id) {
                    $(this).show();
                }
            });
            // Stop background scrolling on IOS
              $('.studio-overlay').on('touchmove', function(e) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            });
        });
        $('.studio-close').click(function () {
            $(".studio-popup").hide();
            $("body").removeClass("popup-open");
            $("html").removeClass("popup-open");

            /* add scroll class for header */
            $('body').bind('DOMMouseScroll', function (e) {
                if (e.originalEvent.detail > 0) {
                    //scroll down
                    $('#header').removeClass('hasScrolled');
                } else {
                    var winPos = $(window).scrollTop();
                    if (winPos >= 150) {
                        $('#header').addClass('hasScrolled');
                    } else {
                        $('#header').removeClass('hasScrolled');
                    }
                }
            });
            $('body').bind('mousewheel', function (e) {
                if (e.originalEvent.wheelDelta < 0) {
                    //scroll down
                    $('#header').removeClass('hasScrolled');
                } else {
                    var winPos = $(window).scrollTop();
                    if (winPos >= 150) {
                        $('#header').addClass('hasScrolled');
                    } else {
                        $('#header').removeClass('hasScrolled');
                    }
                }
            });


        })


        // categories filter
        $("#filter li").click(function () {
            $(this).siblings().removeClass("active");
            $(this).addClass("active");
        });


        // work page start
        $(".title-mobile").click(function () {
            $(this).siblings(".filter").slideToggle();
        })
        $(".filter input").click(function () {
            if ($(window).width() < 767) {
                $(this).parents(".filter").slideUp();
            }
        })


    });


}(jQuery))

$(window).on('load', function () {
    // calculate width of journal
    var totalWidth = 0,
        $margin;
    $('.journal-wrap .listitem').each(function (index) {
        totalWidth += parseInt($(this).outerWidth() + 37, 10);
        // console.log(totalWidth)
    });
    $(".journal-wrap .slideContainer").css("width", totalWidth + 5);

    // calculate width of gallery
    $('.project-gallery').each(function (index) {
        var totalWidthGallery = 0;
        $(this).find('.project-gallery-slide').each(function (index) {
            totalWidthGallery += parseInt($(this).outerWidth() + 66, 10);
        });
        if ($(window).width() > 1365) {
            $margin = 135;

        } else {
            $margin = 71;
        }
        $(this).find(".slideContainer").css("width", totalWidthGallery + $margin);
    });


    // magic scroll start
    var controller = new ScrollMagic.Controller();

    $(".pinContainer").each(function (index, elem) {

        var transformCoeff;
        transformCoeff = $(this).find(".slideContainer").width() - $(window).width();

        $(this).addClass("pinContainer-" + index + "");
        $(this).find(".slideContainer").addClass("slideContainer-" + index + "");
        $(this).children(".panel").addClass("panel-" + index + "");
        wipeAnimation = "wipeAnimation" + index.toString();
        var wipeAnimation = new TimelineMax()
            .to(".slideContainer-" + index + "", 1, {x: "-" + transformCoeff + ""});
        $(".pinContainer-" + index + "").children('.panel-' + index + "").each(function (index, elem) {
            wipeAnimation
                .fromTo(elem, 1, {x: "100%"}, {x: "0%", ease: Linear.easeNone})
        });

        new ScrollMagic.Scene({
            triggerElement: ".pinContainer-" + index + "",
            triggerHook: "onLeave",
            duration: "500%"
        })
            .setPin(".pinContainer-" + index + "", {pushfollowers: false})
            .setTween(wipeAnimation)
            .addIndicators()
            .addTo(controller);
    });
    // hide helper divs
    $("#wrapper").siblings("div").hide();
});




